<template>
  <b-container fluid>
    <b-row style="height: 100vh">
      <b-col md="5" lg="4" class="p-0 d-none d-sm-block">
        <SideBarComponent />
      </b-col>
      <b-col md="7" lg="8" class="py-4">
        <NavComponent />
        <main class="wrapper d-flex align-items-center">
          <div class="w-100">
            <h2 class="h3 text-md-left font-semi-bold mb-2r">
              Recuperar contraseña
            </h2>
            <AlertInfoComponent
              title="Información recuperar contraseña"
              :description="apiResponse.message"
              v-if="apiResponse.message"
            />

            <el-form
              :model="passwordChange"
              :rules="rules"
              ref="formPasswordChange"
              v-if="successChangePassword"
            >
              <b-row>
                <b-col xs="12" sm="12">
                  <el-form-item prop="password">
                    <slot name="label">
                      <label class="font-semi-bold m-0">Nueva Contraseña</label>
                    </slot>
                    <el-input
                      type="password"
                      v-model="passwordChange.password"
                      show-password
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col xs="12" sm="12">
                  <el-form-item prop="passwordConfirm">
                    <slot name="label">
                      <label class="font-semi-bold m-0"
                        >Confirmar Contraseña</label
                      >
                    </slot>
                    <el-input
                      type="password"
                      v-model="passwordChange.passwordConfirm"
                      show-password
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <button
                    class="btn md__btn-primary px-4 mt-2 float-right"
                    @click.prevent="submitForm"
                  >
                    Guardar
                  </button>
                </b-col>
              </b-row>
            </el-form>
          </div>
        </main>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AuthServices from "@/modules/auth/services/AuthServices";
import AlertInfoComponent from "@/components/Alerts/Info";
import SideBarComponent from "../../components/SideBar/SideBar";
import NavComponent from "@/components/Nav/Nav";
import Validate from "@/assets/validate";

export default {
  name: "NewPasswordPage",
  data() {
    return {
      apiResponse: {
        message: null
      },
      successChangePassword: false,
      passwordReset: null,
      passwordChange: {
        password: "",
        passwordConfirm: ""
      },

      rules: {
        password: [
          {
            validator: Validate.rules.compare.password.bind(
              this,
              "formPasswordChange"
            ),
            trigger: "blur"
          }
        ],
        passwordConfirm: [
          {
            validator: Validate.rules.compare.confirmPassword.bind(this),
            trigger: "blur"
          }
        ]
      }
    };
  },

  methods: {
    toggleApiResponse(message = "") {
      this.apiResponse.message = message;
    },
    submitForm() {
      this.$refs.formPasswordChange.validate(async valid => {
        if (valid) {
          this.toggleApiResponse();
          this.$store.dispatch("toggleRequestLoading");
          const data = { ...this.passwordChange, ...this.passwordReset };
          try {
            let response = await AuthServices.passwordRecoveryReset(
              data
            ).finally(() => {
              this.$store.dispatch("toggleRequestLoading");
            });
            if (response) {
              if (response.data.success) {
                this.successChangePassword = false;
              }
              this.toggleApiResponse(response.data.message);
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async handlePasswordResetFind(token) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await AuthServices.passwordRecoveryFind(token).finally(
          () => {
            this.$store.dispatch("toggleRequestLoading");
          }
        );
        if (response.data.success) {
          this.successChangePassword = true;
          this.passwordReset = response.data.data;
        } else {
          this.toggleApiResponse(response.data.message);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    AlertInfoComponent,
    SideBarComponent,
    NavComponent
  },
  props: ["query"],
  created() {
    const { token } = this.$route.params;
    if (token) this.handlePasswordResetFind(token);
    else this.$router.push({ name: "login" });
  },
  mounted() {}
};
</script>

<style scoped lang="scss">
@import "./NewPassword.scss";
</style>
